<template>
  <Popup
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :authUser="authUser"
    :closePopupEvent="closePopup"
    :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
  >
    <socialLogin slot="social"></socialLogin>
    <div class="header-container-auth-forms" slot="component">
      <div class="header-container-auth-forms-auth-container">
        <div class="header-container-auth-forms-auth-container-auth-components">
          <component
            :is="authUser"
            :input="inputField"
            :inputtype="inputtype"
            :formData="formData"
            @change="switchComponent"
            @closePopup="closeAuth()"
          ></component>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { store } from "@/store/store";
import { mapMutations } from "vuex";


export default {
  props: {
    closePopup: {
      type: Function,
    },
    formInfo: {
      type: Object,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "",
      radiusType: "full",
      authHeaderSignup: "",
      authHeaderLogin: "",
      signupWhite: "",
      loginWhite: "",
      enableLoader: false,
      inputField: "",
      inputtype: "",
      authUser: "",
      localDisplayLang: null,
      subscriptions: [],

      formData: null,
    };
  },
  watch: {
    authUser(val) {
      if (val) {
        this.sendFormType();
      }
    },
  },
  computed: {
    ...mapGetters(["getRtl", "subscriberid"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("close-social-captcha", (status) => {
      if (status) {
        this.closeAuth();
      }
    });

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //setting margin for userauth popup based on screen width.
    if (screen.width <= 576) {
      this.margin = "40% auto";
    } else {
      this.margin = "12% auto";
    }

    if (screen.width >= 1024 && screen.width <= 1400) {
      this.width = "35%"
    }

    // eventBus.$on("social-captch-event", (data) => {
    //   console.log("THIS IS THE SOCIAL -- EVENT -- ", data);

    //   let info = {};
    //   info.formType = "socialCaptcha";
    //   // this.formInfo = info;
    //   this.formData = info;
    // });

    

    this.switchComponent(this.formInfo);
  },
  methods: {
		...mapMutations(["setPairingFlag"]),
    sendFormType() {
      setTimeout(() => {
        eventBus.$emit("formType", this.authUser);
      }, 200);
    },

    switchComponent(value) {
      this.inputField = value.input ? value.input : "";
      this.inputtype = value.inputtype ? value.inputtype : "";
      this.authUser = value.formType;

      this.formData = value;
    },

    closeAuth() {
      if (this.formInfo.previousAction) {
        this.checkForSubscriptions()
          .then((response) => {
            if (!response.data.reason) {
              this.subscriptions = response.data.data;
            }
            this.handlePreviousAction(this.formInfo.previousAction);
          })
          .catch((error) => console.log("error", error));
      }
      this.closePopup();
    },

    checkForSubscriptions() {
      let payload = {
        subscriptionstatus: "ALL",
      };
      return store.dispatch("listSubscription", payload);
    },

    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },

    handlePreviousAction(previousAction) {
      if (previousAction.action == "deeplink") {
        if (
          previousAction.data &&
          previousAction.data.userTypes &&
          previousAction.data.userTypes.includes(this.getUserType())
        ) {
          let planid = previousAction.data.deeplink.split("/")[4];
          this.$router.push({ name: "plandeeplink", params: { planid: planid } });
        }
      }
    },
  },

  beforeDestroy() {
    // eventBus.$off("social-captch-event")
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    socialLogin: () => import(/* webpackChunkName: "socialLogin" */ "./socialLogin/socialLogin.vue"),
    login: () => import(/* webpackChunkName: "Login" */ "./Login/login.vue"),
    signup: () => import(/* webpackChunkName: "Signup" */ "./Signup/signup.vue"),
    lookup: () => import(/* webpackChunkName: "lookup" */ "./lookUp/lookUp.vue"),
    socialCaptcha: () => import(/* webpackChunkName: "lookup" */ "./socialCaptcha.vue"),
    verifyResendCaptcha: () => import(/* webpackChunkName: "lookup" */ "./verifyResendCaptcha.vue"),
    mobile: () => import(/* webpackChunkName: "mobile" */ "./mobile/mobileVerification.vue"),
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./userAuthOld.scss"
</style>
