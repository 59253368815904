<template>
  <Popup :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="change-password" slot="component">
      <p class="change-password-title">
        {{ $t("change password") }}
        <!-- <br />
        {{ $t("to") }} {{ $t("change") }} -->
      </p>
      <form @submit.prevent="changePassword()">
        <div class="form-group">
            <!--
              <div v-if="formError" class="error-container form-control">
            <p class="error">{{ formError }}</p>
          </div>
            -->   


            <!--
                <div class="form-control">
            <label for="Old Password">{{ $t("enter old password") }}</label>
            <div class="input-container">
              <input type="password" v-model="userOldPassword" :placeholder="oldPasswordPlaceholder" />
              <span :class="localDisplayLang === 'ara' ? 'rtl-eye' : 'eye'">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
            -->
          
          <JhakaasInput 
        
        inputType="password"
        :label="$t('enter old password')"
        inputId="old-password"
        reference="old-password"
        :helperText="oldPasswordError"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        autocomplete="false"
        @keydown.space.prevent
        required
        :value="userOldPassword"
        @onChange="value => userOldPassword = value"
        >
        <template
        v-slot:endIcon
        >
          <span class="eye">
            <img 
            style="width: 20px;"
            :src="eyeopen" @click="toggleEye($event)" alt />
          </span>
        </template>
      
      </JhakaasInput>


       <!--
           <div class="form-control">
            <label for="New Password">{{ $t("enter new password") }}</label>
            <div class="input-container">
              <input type="password" v-model="userNewPassword" :placeholder="newPasswordPlaceholder" />
              <span :class="localDisplayLang === 'ara' ? 'rtl-eye' : 'eye'">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
       -->


        <JhakaasInput 
        
        inputType="password"
        :label="$t('enter new password')"
        inputId="new-password"
        reference="new-password"
        :helperText="formError"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        autocomplete="false"
        @keydown.space.prevent
        required
        :value="userNewPassword"
        @onChange="value => userNewPassword = value"
        >
        <template
        v-slot:endIcon
        >
          <span class="eye">
            <img 
            style="width: 20px;"
            :src="eyeopen" @click="toggleEye($event)" alt />
          </span>
        </template>
      
      </JhakaasInput>

        </div>



        <!--
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"

        -->
        <button
          class="button-primary update"
          type="submit"
          ref="update"
        >
          {{ $t("update") }}
        </button>
      </form>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";
import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import SimpleCrypto from "simple-crypto-js";
import { _providerId } from "@/provider-config.js";
import { showSnackBar } from '../../../utilities';

export default {
  props: {
    closePopup: {
      type: Function,
    },
  },
  data() {
    return {
      userNewPassword: "",
      userOldPassword: "",
      newPasswordPlaceholder: "",
      oldPasswordPlaceholder: "",
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      width: "50%",
      margin: "10% auto",
      radiusType: "full",
      formError: null,
      oldPasswordError: "",
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["secret_key", "getRtl"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
          this.oldPasswordError = "";
        }, 5000);
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    this.newPasswordPlaceholder = this.$t("enter new password");
    this.oldPasswordPlaceholder = this.$t("enter old password");

    eventBus.$on("changePasswordResponse", (response) => {
      if (response.reason) {
        // this.$refs.update.disabled = false;
        this.formError =this.$t("Incorrect Old Password");
        this.userNewPassword = "";
        this.userOldPassword = "";
      } else {
        if (localStorage.getItem("loginType")) {
          let LoginType = JSON.parse(localStorage.getItem("loginType"));
          if (LoginType.type == "Email") {
            let simpleCrypto = new SimpleCrypto(this.secret_key);
            LoginType.password = simpleCrypto.encrypt(this.userNewPassword);
            // save back to storage
            localStorage.setItem("loginType", JSON.stringify(LoginType));
          }
        }
        // Show the snackbar message
        // snackbar.show({
        //   text: "Password Changed Successfully!",
        //   pos: "bottom-center",
        // });

        showSnackBar(this.$t("Password Changed Successfully!"));
        this.closePopup();
      }
    });
  },
  methods: {
    changePassword() {

      if(!this.validateOldPassword(this.userOldPassword)) {
    return;
  }

  if(!this.validatePassword(this.userNewPassword)) {
    return; 
  }
      if (!this.validatePassword(this.userNewPassword) || !this.validateOldPassword(this.userOldPassword)) return;
      if (this.userNewPassword === this.userOldPassword) {
        this.formError = this.$t("Password should not be same");
        this.userNewPassword = "";
        // this.userOldPassword = "";
        return;
      }
      let payload = {
        password: this.userNewPassword,
        oldpassword: this.userOldPassword,
      };
      eventBus.$emit("changePassword", payload);
      // this.$refs.update.disabled = true;
    },

    validatePassword(password) {
      this.formError = null;
      if (!password) {
        this.formError = this.$t("Password Required!");
        return false;
      } else if (password.length < 6) {
        this.formError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.formError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    },
    validateOldPassword(password) {
      this.formError = null;
      if (!password) {
        this.oldPasswordError = this.$t("Old Password Required!");
        setTimeout(() => {
      this.oldPasswordError = ''; 
    }, 5000);
        return false;
      } else if (password.length < 6) {
        this.oldPasswordError = this.$t("Password should be minimum 6 characters");
        return false;
      } else if (password.length > 16) {
        this.oldPasswordError = this.$t("Password should be maximum 16 characters");
        return false;
      }

      return true;
    }
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    JhakaasInput: () => import("@/components/utils/JhakaasInput.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./ChangePassword.scss"
</style>
