import { store } from "@/store/store";

export function actGetSearchData (payload) {
    return new Promise((resolve, reject) => {

        store.dispatch("contentSearch", payload).then((response) => {
          if (response.data.reason) {
            reject( {
                isError: true,
                reason: response.data.reason,
                dataset: []
            })
          } else {
            resolve({
                isError: false,
                dataset: response.data.data,
                totalcount: response.data.totalcount
            })
          }
        }).catch((err) =>  {
            reject( {
                isError: true,
                reason: err,
                dataset: []
            })
        });

    })
}



export function actGetPopulerList (payload) {
    return new Promise((resolve, reject) => {

        store.dispatch("contentList", payload).then((response) => {
          if (response.data.reason) {
            reject( {
                isError: true,
                reason: response.data.reason,
                dataset: []
            })
          } else {
            resolve({
                isError: false,
                dataset: response.data.data
            })
          }
        }).catch((err) =>  {
            reject( {
                isError: true,
                reason: err,
                dataset: []
            })
        });

    })
}

